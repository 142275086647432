nav {
  ul {
    margin: 0;
    list-style: none;
  }
  li {
    display: block;

    @include breakpoint-sm {
      display: inline-block;
    }
    a {
      display: block;
      position: relative;
      padding: 1.2em 1.1em;
      font-family: $heading-font-family;
      font-weight: 400;
      color: $primary-light;
      font-size: 11px;
      letter-spacing: 0.08em;
      text-decoration: none;
      text-transform: uppercase;

      &:active,
      &.hover {
        color: $body-copy-darken;
      }
    }
  }
}
