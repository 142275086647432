.js-body-fade-in {
  position: relative;
  @include transition-basic(opacity, $transition-speed-long);
  opacity: 0;
}
.js-body-fade-in.complete {
  opacity: 1;
}
.js-fade-in {
  @include transition-basic(opacity, $transition-speed-medium);
  opacity: 0;
}
.js-fade-in.in {
  opacity: 1;
}
