#images-container {
  position: relative;
  padding-bottom: 66.666666%;
}
.slideshow-img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
.slideshow-caption-section {
  margin: $unit-3 auto $unit-8;
  text-align: center;

  @include breakpoint-md {
    margin-top: $unit-4;
  }
  @include breakpoint-md {
    margin-bottom: $unit-10;
  }
  @include breakpoint-lg {
    margin: $unit-16 0 0;
  }

  svg {
    max-width: $unit-2x;
  }
}
#captions-container {
  position: relative;
}
.slideshow-caption {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  letter-spacing: 0.02em;

  span {
    @include relative-top(-7px);
    display: block;
    font-size: 12px;
    font-style: italic;
    letter-spacing: 0.03em;
  }
  @include breakpoint-sm {
    margin-top: 0.8em;
    font-size: 15px;

    span {
      font-size: 13px;
    }
  }
  @include breakpoint-md {
    font-size: 16px;

    span {
      font-size: 14px;
    }
  }
}
.slideshow-img,
.slideshow-caption {
  opacity: 0;
  @include transition-basic(opacity, $transition-speed-long);
}
.slideshow-img.showing,
.slideshow-caption.showing {
  opacity: 1;
}
