$primary-color: #909090;
$primary-light: #aaa;
$secondary-color: #d8d8d8;
$background-color: #f6f6f6;

$border-color: #e1e1e1;

$shadow-color: rgba(0, 0, 0, 0.15);

$body-copy: $primary-color;
$body-copy-darken: darken($body-copy, 10%);
$body-copy-alt: #c0c0c0;
