@charset "utf-8";

@import 'reset';

@import 'colors';
@import 'variables';
@import 'mixins';
@import 'media-queries';
@import 'fonts';
@import 'global';
@import 'utilities';
@import 'animations';

@import 'components/takeover';
@import 'components/header';
@import 'components/footer';
@import 'components/nav';
@import 'components/slideshow';
