.site-footer {
  text-align: center;

  @include breakpoint-sm {
    text-align: initial;
  }
  nav {
    ul {
      @include breakpoint-sm {
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    a {
      color: $body-copy-alt;
      font-size: 12px;

      &:hover {
        color: $primary-light;
      }
    }
  }
}
.footer-addendum {
  opacity: 0.5;
  @include transition-basic();

  &:hover {
    opacity: 1;
  }
}
.copyright {
  margin: 0;
  padding: $unit-6 0 4px;
  text-align: center;

  a {
    font-size: 10px;
    color: $body-copy-alt;
    text-decoration: none;

    &:hover {
      color: $body-copy-alt;
      text-decoration: underline;
    }
  }
}
