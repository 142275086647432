* {
  box-sizing: border-box;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
}
table {
  width: 100%;
  border-collapse: collapse;
}
