.site-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 $unit-1;
  @include transition-s-curve(all, 1000ms);
  opacity: 1;
  z-index: 2;

  @include breakpoint-sm {
    padding: 0 $unit-2;
  }
}
.logo-type-container {
  position: relative;
  display: inline-block;
  margin-left: -$unit-1;
  padding: 0 $unit-1;
  line-height: 0;

  svg {
    position: relative;
    top: 50%;
    width: $unit-4;
    transform: translateY(-50%);
  }
  svg path {
    stroke: $primary-color;
    @include transition-basic();
  }
  &:hover {
    svg path {
      stroke: #000;
    }
  }
}
.hamburger {
  display: block;
  margin-right: -0.8em;
  padding: 1.2em 0.8em;

  img {
    width: $unit-1;
  }
}
