$static-url: 'https://static.sinistrocular.com';

$base-font-size: 14px;
$base-font-weight: 400;
$small-font-size: $base-font-size * 0.9;
$base-line-height: 1.75;

$transition-speed-default: 400ms;
$transition-speed-short: 600ms;
$transition-speed-medium: 850ms;
$transition-speed-long: 2.2s;
$transition-speed-extra-long: 4.6s;
$transition-easing-default: ease-in-out;

$_base-unit: 18px;

$unit-1: $_base_unit;
$unit-2: $_base_unit * 2;
$unit-2x: $_base_unit * 2.5; // special for logo
$unit-3: $_base_unit * 3;
$unit-3x: $_base_unit * 3.5; // special for logo
$unit-4: $_base_unit * 4;
$unit-5: $_base_unit * 5;
$unit-6: $_base_unit * 6;
$unit-8: $_base_unit * 8;
$unit-10: $_base_unit * 10;
$unit-12: $_base_unit * 12;
$unit-14: $_base_unit * 14;
$unit-16: $_base_unit * 16;
$unit-20: $_base_unit * 20;
$unit-24: $_base_unit * 24;
$unit-32: $_base_unit * 32;
