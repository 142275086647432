.takeover {
  @include fixed-all();
  text-align: center;
  background-color: $background-color;
  opacity: 0;
  pointer-events: none;
  @include transition-basic();
  z-index: 3;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
}
.close-x {
  display: block;
  position: absolute;
  top: 13px;
  right: 17px;

  img {
    width: 20px;
  }
}
.takeover-nav-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40px;
  height: 100%;

  a {
    font-size: 13px;
  }
}
